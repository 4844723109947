
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardComponent",
  props: {
    slim: {
      type: Boolean,
      required: false,
    },
    contentBlocked: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name];
    },
  },
});
