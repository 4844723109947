
import { defineComponent, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { useRouter } from "vue-router";

interface ComponentData {
  submitButtonLoading: boolean;
  isActive: boolean;
  languageId: number;
}

export default defineComponent({
  name: "VoucherCreatePage",
  components: {
    CardComponent,
    Field,
    FormSubmitButton,
    ErrorMessage,
  },

  setup() {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      isActive: false,
      languageId: 1,
    });

    const router = useRouter();
    const { handleSubmit, setFieldError } = useForm();

    const createVoucher = handleSubmit((values) => {
      const requestBody = {
        ...values,
        is_active: state.isActive,
        translations: [
          {
            language_id: state.languageId,
            title: values.title,
            description: values.description,
          },
        ],
      };

      state.submitButtonLoading = true;
      ApiService.post(`voucher`, requestBody)
        .then(({ data }) => {
          ElMessage.success("Voucher added successfully!");
          router.push({
            name: "vouchers-view",
            params: { id: data.id },
          });
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    return {
      state,
      createVoucher,
    };
  },
});
