<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 m-auto">
        <card-component>
          <template #header>Create voucher</template>
          <template #default>
            <form autocomplete="off" @submit="createVoucher">
              <div class="row m-2">
                <div class="col-3 m-auto">
                  <label for="title" class="form-label float-end">Title</label>
                </div>
                <div class="col-9">
                  <Field
                    id="title"
                    name="title"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Title"
                    autocomplete="nope"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="translations.0.title" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-3 m-auto">
                  <label for="description" class="form-label float-end">
                    Description
                  </label>
                </div>
                <div class="col-9">
                  <Field
                    id="description"
                    name="description"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Description"
                    autocomplete="nope"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="translations.0.description" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-3 m-auto">
                  <label for="code" class="form-label float-end"> Code </label>
                </div>
                <div class="col-9">
                  <Field
                    id="code"
                    name="code"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Code"
                    required
                    autocomplete="nope"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="code" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-3 my-auto">
                  <label for="value" class="form-label float-end">
                    Value
                  </label>
                </div>
                <div class="col-3">
                  <Field
                    id="value"
                    name="value"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Value"
                    required
                    autocomplete="nope"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="value" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-3 pt-2">
                  <label for="is_active" class="form-label float-end">
                    Is active
                  </label>
                </div>
                <div class="col-3 pt-2">
                  <div class="form-check form-check-custom form-check-solid">
                    <input
                      id="is_active"
                      v-model="state.isActive"
                      name="is_active"
                      class="form-check-input"
                      type="checkbox"
                      autocomplete="off"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="is_active"></ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col text-center mb-5">
                  <form-submit-button :loading="state.submitButtonLoading">
                    Create Voucher
                  </form-submit-button>
                </div>
              </div>
            </form>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { useRouter } from "vue-router";

interface ComponentData {
  submitButtonLoading: boolean;
  isActive: boolean;
  languageId: number;
}

export default defineComponent({
  name: "VoucherCreatePage",
  components: {
    CardComponent,
    Field,
    FormSubmitButton,
    ErrorMessage,
  },

  setup() {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      isActive: false,
      languageId: 1,
    });

    const router = useRouter();
    const { handleSubmit, setFieldError } = useForm();

    const createVoucher = handleSubmit((values) => {
      const requestBody = {
        ...values,
        is_active: state.isActive,
        translations: [
          {
            language_id: state.languageId,
            title: values.title,
            description: values.description,
          },
        ],
      };

      state.submitButtonLoading = true;
      ApiService.post(`voucher`, requestBody)
        .then(({ data }) => {
          ElMessage.success("Voucher added successfully!");
          router.push({
            name: "vouchers-view",
            params: { id: data.id },
          });
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    return {
      state,
      createVoucher,
    };
  },
});
</script>

<style scoped></style>
