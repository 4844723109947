<template>
  <button
    type="submit"
    class="btn btn-sm btn-secondary"
    :disabled="isLoading === true || isDisabled === true"
  >
    <span class="indicator-label"> <slot>Update</slot> </span>

    <span
      v-if="isLoading"
      class="spinner-border spinner-border-sm align-middle ms-2"
    />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormSubmitButton",
  props: {
    loading: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoading() {
      return this.loading === true;
    },
    isDisabled() {
      return this.disabled === true;
    },
  },
});
</script>

<style scoped></style>
