<template>
  <div class="card shadow-sm">
    <div
      v-if="hasSlot('header')"
      class="card-header p-4"
      :class="{
        'min-h-auto': slim,
      }"
    >
      <h3
        class="card-title"
        :class="{
          'fs-6': slim,
        }"
      >
        <slot name="header" />
      </h3>
      <div class="card-toolbar m-0">
        <slot name="toolbar" />
      </div>
    </div>
    <div
      class="card-body px-4 py-0"
      :class="{
        overlay: contentBlocked,
        'overlay-block': contentBlocked,
      }"
    >
      <slot />
      <div
        v-if="contentBlocked"
        class="overlay-layer rounded-bottom bg-dark bg-opacity-5"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if="hasSlot('footer')" class="card-footer p-4">
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardComponent",
  props: {
    slim: {
      type: Boolean,
      required: false,
    },
    contentBlocked: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name];
    },
  },
});
</script>

<style scoped>
.card .card-header {
  min-height: 30px !important;
  padding: 0.5rem 0.5rem 0.5rem 1rem !important;
}
.card .card-title {
  font-size: 1.1rem;
}
</style>
