
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormSubmitButton",
  props: {
    loading: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoading() {
      return this.loading === true;
    },
    isDisabled() {
      return this.disabled === true;
    },
  },
});
